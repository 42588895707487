import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Portfolio.css';
import './App.css';

const Portfolio = () => {
  return (
    <div className="portfolio-container">
      <h1>My Projects</h1>
      
      {/* Project 1: RISC-V Simulator */}
      <div className="project text-box" id="project1">
        <h2>RISC-V Simulator</h2>
        <div className="project-description">
          <p>
            The RISC-V Simulator is a 32-bit processor simulator built using C++. It allows users to learn the fundamentals of how a RISC-V CPU processes instructions, interacts with memory, and manages registers. The simulator decodes RISC-V instructions, simulates memory, and provides a step-by-step execution feature for users to observe how the CPU operates.
          </p>
          <p>
            <a href="/projects/riscv-simulator" className="project-link">
              Full writeup
            </a>
          </p>
        </div>
      </div>

      {/* Add more projects in similar text boxes */}
    </div>
  );
};

export default Portfolio;