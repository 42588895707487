import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; 
import './Navbar.css'; // Import the navbar-specific CSS file

const Header = () => {
  const navbarCollapseRef = useRef(null);

  const handleNavLinkClick = () => {
    if (navbarCollapseRef.current) {
      const bsCollapse = new window.bootstrap.Collapse(navbarCollapseRef.current, {
        toggle: false
      });
      bsCollapse.hide();
    }
  };

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark fixed-top">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/" onClick={handleNavLinkClick}>
            ryoncook.dev
          </Link>
          <button 
            className="navbar-toggler" 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target="#navbarNav" 
            aria-controls="navbarNav" 
            aria-expanded="false" 
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div 
            className="collapse navbar-collapse" 
            id="navbarNav" 
            ref={navbarCollapseRef}
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link text-white" to="/about" onClick={handleNavLinkClick}>About</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" to="/portfolio" onClick={handleNavLinkClick}>Portfolio</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link text-white" to="/contact" onClick={handleNavLinkClick}>Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;